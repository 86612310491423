<template>
  <div class="page">
    <Header />
    <div class="container">
        <div class="row mb--sm--9 mb--md--20 mt--sm--9 mt--md--20">
            <div class="col col--sm--12 col--md--6 col--md--offset--3">
                <div class="h5 text--black font-weight--400 mb--sm--20 text--sm--center forTabletUp">Đổi mật khẩu</div>
                <form action="" @submit.prevent="formSubmitHandle">
                  <HdInput
                    v-model="passwordOld"
                    type="password"
                    label="Nhập mật khẩu hiện tại:"
                    placeholder="Mật khẩu"
                    required
                  >
                    <template v-slot:icon><InputPasswordIcon/></template>
                  </HdInput>
                  <HdInput
                    v-model="password"
                    type="password"
                    label="Mật khẩu mới:"
                    placeholder="Mật khẩu mới"
                    helpText="Ít nhất 8 kí tự"
                    required
                  >
                    <template v-slot:icon><InputNewPasswordIcon/></template>
                  </HdInput>
                  <HdInput
                    v-model="confirmPassword"
                    type="password"
                    label="Xác nhận mật khẩu mới:"
                    placeholder="Nhập lại mật khẩu mới"
                    helpText="Ít nhất 8 kí tự"
                    required
                  >
                    <template v-slot:icon><InputConfirmNewPasswordIcon/></template>
                  </HdInput>
                  <div class="text--sm--center mt--sm--8">
                    <HdButton nativeType="submit" fullWidth>Xác nhận</HdButton>
                  </div>
                </form>
            </div>
        </div>
    </div>
    <Footer/>
    <FooterMobile/>
  </div>
</template>

<script>
import bcrypt from 'bcryptjs'

// Import Layouts
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'

// Impport Components
import HdInput from '@/components/HdInput'
import HdButton from '@/components/HdButton'
import InputPasswordIcon from '@/assets/input-password-icon.svg'
import InputNewPasswordIcon from '@/assets/input-new-password-icon.svg'
import InputConfirmNewPasswordIcon from '@/assets/input-confirm-new-password-icon.svg'

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    HdInput,
    HdButton,
    InputPasswordIcon,
    InputNewPasswordIcon,
    InputConfirmNewPasswordIcon
  },
  data () {
    return {
      passwordOld: '',
      password: '',
      confirmPassword: ''
    }
  },
  methods: {
    formSubmitHandle: function (event) {
      const { passwordOld, password, confirmPassword } = this

      if (!bcrypt.compareSync(passwordOld, this.$store.state.auth.user.password)) {
        return this.$alert(this.$t('changePassword.confirmCurrentPassword'), 'Thông báo', {
          confirmButtonText: 'OK'
        })
      }

      if (password.length < 8 || password.length > 12) {
        return this.$alert('Mật khẩu mới phải có độ dài ít nhất 8 ký tự và tối đa 12 ký tự!', 'Thông báo', {
          confirmButtonText: 'OK'
        })
      }

      if (password !== confirmPassword) {
        return this.$notify.error({
          duration: 1000,
          title: 'Lỗi!',
          message: this.$t('changePassword.confirmPassword')
        })
      }

      this.$store.dispatch('auth/changePassword', { password }).then(response => {
        this.$notify({
          duration: 1000,
          title: 'Thành công!',
          message: this.$t('changePassword.success'),
          type: 'success'
        })
        this.$router.push('/change-password-success').catch(() => {})
      }, error => {
        if (error && error.response) {
          this.$notify.error({
            duration: 1000,
            title: 'Lỗi!',
            message: error.response.data.error.message.value || error.message
          })
        }
      })
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: true,
      headingText: 'Đổi mật khẩu',
      back: true,
      search: false,
      notify: true,
      cart: true
    })
  }
}
</script>
